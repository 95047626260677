import store from '@/store';
import { loadLanguageAsync } from '@/i18n';
import OrganizationsManagement from './OrganizationsManagement';
import OrganizationAssignedQuotas from './OrganizationAssignedQuotas';
import OrganizationList from './OrganizationList';
import AddOrganization from './AddOrganization';
import EditOrganization from './EditOrganization';
import OrganizationPage from './OrganizationPage';
import OrganizationDetails from './OrganizationDetails';
import NativeConfigurationView from './security/NativeConfigurationView';
import LdapConfigurationView from './security/LdapConfigurationView';
import AutoAccountCreationConfigView from './security/AutoAccountCreationConfigView';
import ManageAssignedServices from './services/ManageAssignedServices';
import ManageVerifiedDomains from './domains/ManageVerifiedDomains';
import AddVerifiedDomain from './domains/AddVerifiedDomain';
import PageNotFound from '../components/PageNotFound';

import OrganizationBillingDetails from './billing/OrganizationBillingDetails';
import BillableOrgInfoPackageEdit from './billing/BillableOrgInfoPackageEdit';
import EditCreditCard from './billing/EditCreditCard';
import BillingOverview from './billing/BillingOverview';
import AddOrganizationDiscount from './billing/discount/AddOrganizationDiscount.vue';
import BillingCycleDetails from '@/app/Main/components/billing_cycles/BillingCycleDetails';

import CommitmentDetailsWrapper from '@/app/Main/components/commitments/CommitmentDetailsWrapper';
import AddCommitment from '@/app/Main/components/commitments/AddCommitment';
import EditCommitment from '@/app/Main/components/commitments/EditCommitment';
import EditOrganizationDiscount from '@/app/Main/Organizations/billing/discount/EditOrganizationDiscount.vue';
import ConnectionAssignmentForm from './services/ConnectionAssignmentForm.vue';

const PageNotFoundRoute = { path: 'notFound', component: PageNotFound, replace: true };

const orgLabel = () => {
  if (store.getters.hasOtherOrgAccess) {
    return 'organizations';
  }
  return 'organization';
};

export default [
  {
    path: 'admin/organizations',
    component: OrganizationsManagement,
    async beforeEnter(to, from, next) {
      const userLang = store.getters.userContext.locale;
      await Promise.all([
        loadLanguageAsync('organizations', userLang),
        loadLanguageAsync('admin', userLang),
      ]);
      if (!store.getters.hasOtherOrgAccess
        && !to.path.includes(store.getters.myOrganization.id)) {
        return next({ path: `/admin/organizations/${store.getters.myOrganization.id}` });
      }
      return next();
    },
    meta: {
      protected: ['admin:orgs', 'reseller:orgs'],
      isProtectUnion: false,
    },
    children: [
      {
        name: 'organizationList',
        path: '',
        component: OrganizationList,
        meta: {
          title: orgLabel,
        },
      },
      {
        name: 'addOrganization',
        path: 'add',
        component: AddOrganization,
        props: { switchOrgRouteName: 'organizationList' },
      },
      {
        path: 'mine',
        beforeEnter: (to, from, next) => next({ path: `/admin/organizations/${store.getters.myOrganization.id}` }),
      },
      {
        path: ':id',
        meta: {
          title: orgLabel,
        },
        component: OrganizationPage,
        props: route => ({ ...route.params, id: route.params.id, switchOrgRouteName: 'organizationList' }),
        children: [
          {
            path: '',
            redirect: { name: 'organizationDetails' },
          },
          {
            name: 'organizationDetails',
            path: 'details',
            component: OrganizationDetails,
          }, {
            name: 'editOrganization',
            path: 'details/edit',
            component: EditOrganization,
          }, {
            name: 'addSubOrganization',
            path: 'details/add',
            props: route => ({ ...route.params, switchOrgRouteName: 'organizationList' }),
            component: AddOrganization,
          }, {
            name: 'organizationServices',
            path: 'services',
            component: ManageAssignedServices,
          }, {
            name: 'assignOrganizationService',
            path: 'services/assign',
            component: ConnectionAssignmentForm,
          }, {
            name: 'quotas-assigned',
            path: 'quotas',
            component: OrganizationAssignedQuotas,
          },
          {
            name: 'organizationSecurity',
            path: 'security',
            redirect: { name: 'nativeConfig' },
            meta: {
              protected: ['reseller:orgs'],
            },
            children: [
              {
                name: 'nativeConfig',
                path: 'nativeConfiguration',
                component: NativeConfigurationView,
              },
              {
                name: 'ldapConfig',
                path: 'ldapConfiguration',
                component: LdapConfigurationView,
              },
              {
                name: 'autoAccountCreationConfig',
                path: 'autoAccountCreationConfiguration',
                component: AutoAccountCreationConfigView,
              },
            ],
          },
          {
            name: 'verifiedDomains',
            path: 'domains',
            component: ManageVerifiedDomains,
            meta: {
              protected: ['reseller:authentication'],
            },
          },
          {
            name: 'addVerifiedDomain',
            path: 'domains/add',
            component: AddVerifiedDomain,
            meta: {
              protected: ['reseller:authentication'],
            },
          },
          {
            name: 'org-billing',
            path: 'billing',
            redirect: { name: 'billing-overview' },
          },
          {
            name: 'billable_org_info_edit',
            path: 'billing/edit',
            component: BillableOrgInfoPackageEdit,
            props: route => ({ cycleId: (route.params.cycleId || '') }),
            meta: {
              protected: ['reseller:organizationBilling']
            },
          },
          {
            name: 'billing-overview',
            path: 'billing/overview',
            component: BillingOverview,
            meta: {
              protected: ['admin:billing', 'reseller:organizationBilling'],
              isProtectUnion: false
            },
          },
          {
            name: 'billing-cycle-details',
            path: 'billing/overview/cycles/:cycleId',
            component: BillingCycleDetails,
            props: route => ({ ...route.params, id: route.params.cycleId }),
            meta: {
              protected: ['reseller:organizationBilling', 'admin:billing'],
              isProtectUnion: false,
              title: () => 'monetization.billing_cycle_title',
            },
          },
          {
            name: 'commitment-details-wrapper',
            path: 'billing/overview/cycles/:cycleId/commitments/:commitmentId/details',
            component: CommitmentDetailsWrapper,
            props: route => ({ ...route.params, switchOrgRouteName: 'commitment-list' }),
            meta: {
              protected: ['admin:billing'],
              title: () => 'monetization.commitments.title',
            },
          },
          {
            name: 'edit-commitment',
            path: 'billing/overview/commitments/:commitmentId/details/edit',
            component: EditCommitment,
            meta: {
              protected: ['reseller:organizationBilling'],
              title: () => 'monetization.commitments.operations.edit.title',
            },
            props: true
          },
          {
            name: 'add-organization-discount',
            path: 'billing/overview/discounts/add',
            component: AddOrganizationDiscount,
            meta: {
              protected: ['reseller:organizationBilling'],
              title: () => 'monetization.discounts.percentage.add',
            },
          },
          {
            name: 'add-organization-credit',
            path: 'billing/overview/credits/add',
            component: AddOrganizationDiscount,
            meta: {
              protected: ['reseller:organizationBilling'],
              title: () => 'monetization.discounts.credit.add',
            },
          },
          {
            name: 'edit-organization-credit',
            path: 'billing/overview/credits/:creditId/edit',
            component: EditOrganizationDiscount,
            props: route => ({ ...route.params, credit: true }),
            meta: {
              protected: ['reseller:organizationBilling'],
              title: () => 'monetization.discounts.credit.edit',
            },
          },
          {
            name: 'edit-organization-discount',
            path: 'billing/overview/discounts/:discountId/edit',
            component: EditOrganizationDiscount,
            props: route => ({ ...route.params }),
            meta: {
              protected: ['reseller:organizationBilling'],
              title: () => 'monetization.discounts.percentage.edit',
            },
          },
          {
            name: 'add-commitment',
            path: 'billing/overview/commitments/add',
            component: AddCommitment,
            meta: {
              protected: ['reseller:organizationBilling'],
              title: () => 'monetization.commitments.operations.create.title',
            },
          },
          {
            name: 'org-billing-details',
            path: 'billing/details',
            component: OrganizationBillingDetails,
            meta: {
              protected: ['admin:billing', 'reseller:organizationBilling'],
              isProtectUnion: false
            },
          },
          {
            name: 'edit-credit-card',
            path: 'billing/creditcard/edit',
            component: EditCreditCard,
            props: true,
            meta: {
              protected: ['admin:billing'],
            },
            beforeEnter: (to, from, next) => {
              if (to.params.id === store.getters.myOrganization.id) {
                next();
              } else {
                next(PageNotFoundRoute);
              }
            },
          },

        ],
      },
    ],
  },
];
