<template>
  <base-list 
    ariaLabel="service_connections" 
    :ariaRowCount="totalRows"
  >
    <list-header>
      <list-column>{{ $t('service_connection') }}</list-column>
      <list-column>{{ $t('state') }}</list-column>
      <list-column>{{ $t('quota') }}</list-column>
      <list-column>{{ $t('linked_to_service_organization') }}</list-column>
    </list-header>
    <ConnectionAssignmentRow 
      v-for="sc in connections" 
      :key="sc.id+componentKey"
      :assigned="connectionIsAssigned(sc)" 
      :connection="sc" 
    />
    <empty-message 
      v-if="connections.length < 1" 
      icon="fa fa-plug" 
      textLabel="no_available_connections"
    ></empty-message>
  </base-list>
</template>
<script>
import ConnectionAssignmentRow from './ConnectionAssignmentRow';

export default {
  name: 'OrganizationServicesList',
  components: { ConnectionAssignmentRow },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    availableConnections: {
      type: Array,
      required: true,
    },
    componentKey: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selectedConnections: [],
    };
  },
  computed: {
    assignedConnections() {
      return [...this.organization.serviceConnections];
    },
    assignedConnectionState() {
      return this.assignedConnections.reduce((acc, a) => {
        acc[a.id] = a.state;
        return acc;
      }, {});
    },
    connectionQuotas() {
      return (this.organization.quotas || []).reduce((acc, q) => {
        acc[q.serviceConnection.id] = q.id;
        return acc;
      }, {});
    },
    connections() {
      return this.availableConnections.map(sc => ({
        ...sc,
        quotaId: this.quotaOrDefault(sc),
        state: this.assignedConnectionState[sc.id],
      }));
    },
    totalRows() {
      return this.connections.filter(c => this.connectionIsAssigned(c)).length;
    },
  },
  methods: {
    connectionIsAssigned(connection) {
      return !!this.organization.serviceConnections.find(sc => sc.id === connection.id);
    },
    quotaOrDefault(sc) {
      if (this.connectionQuotas[sc.id]) {
        return this.connectionQuotas[sc.id];
      } else if (sc.quotas && sc.quotas.length > 0) {
        return sc.quotas[0].id;
      }
      return undefined;
    },
  },
};
</script>
<style scoped lang="scss">
.checkbox-offset {
  padding-right: 40px;
}
</style>
